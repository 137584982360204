@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family:
    "Poppins",
    sans-serif,
    -apple-system,
    BlinkMacSystemFont;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bodyContainer {
  overflow: hidden;
}
code {
  font-family: "Poppins", sans-serif, source-code-pro, Menlo, Monaco, Consolas,
    monospace;
}

#default-search {
  color: #000;
}

.menuItem .submenu {
  position: fixed;
  left: 0;
  top: 90px;
  background: #fff;
  right: 0;
  padding: 0 10%;
  opacity: 0;
  display: none;
  height: 0;
  transition: all 0.5s ease-in-out;
}
.menuItem .submenu a {
  margin: 10px 5px;
}
.menuItem .subMenu .link-icon-menu {
  height: 30px;
  width: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ddd;
  border-radius: 50%;
  margin-right: 10px;
  transition: all 0.5s ease-in-out;
}
li.menuItem .submenu.active {
  position: fixed;
  opacity: 1;
  height: auto;
  z-index: 9;
  display: grid;
  transition: all 0.5s ease-in-out;
}
.link-icon-menu {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
li.menuItem.active > a {
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.mobile-toggler {
  font-size: 25px;
  position: absolute;
  right: 14px;
  top: 4px;
  display: none;
}
.overlayMain {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  justify-content: center;
  background: #000000a6;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  z-index: 9999;
}
.overlayMain.active {
  display: flex;
}
span.itemCount {
  font-size: 10px;
  color: #fff;
  background: #0ea5e9;
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  left: -11px;
  top: -9px;
}
.profile-image-fallback {
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  background: #038dce;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  font-size: 21px;
}
[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
[type="number"]:focus,
[type="number"]:hover {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

@media screen and (max-width: 1024px) {
  .menuItem .submenu {
    position: inherit;
    transition: none;
  }
  .mobile-toggler {
    display: inline;
  }
  .top-menu.active {
    display: flex;
  }
  .top-menu {
    display: flex;
    position: fixed;
    width: 50%;
    right: 0;
    height: 100vh;
    background: #fff;
    z-index: 9;
    top: 0;
    overflow-x: auto;
    padding-top: 28px;
    top: 0;
  }
  .top-menu ul {
    flex-direction: column;
    justify-content: flex-start;
  }
  .top-menu ul li a {
    font-size: 18px;
  }
  .top-menu ul li {
    margin-bottom: 10px;
    width: 100%;
  }
  .top-menu ul {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    align-items: baseline;
    padding-left: 12px;
  }
  li.menuItem .submenu.active {
    opacity: 1;
    height: auto;
    z-index: 1;
    transition: all 0.5s ease-in-out;
    position: inherit;
    width: 100%;
  }
}
