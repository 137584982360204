.oc-message.by {
  background: #ddd;
  margin: 10px;
  padding: 10px 10px 20px 10px;
  max-width: 80%;
  float: right;
  border-radius: 5px;
  display: flex;
  min-width: 160px;
  position: relative;
  box-shadow: 4px 4px 7px #ccc;
}
.oc-message-item.me {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  position: relative;
}
div.userImg {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid transparent;
  box-shadow: 1px 1px 10px #ccc;
  margin: 10px;
  overflow: hidden;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
  background-color: #038dce;
}
div.userImg img {
  max-width: 100%;
  height: auto;
}
.oc-message-item.other {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  flex-direction: row-reverse;
}
.oc-message-item.other div.userImg {
  float: left;
  margin: 10px;
}
.oc-message.from {
  background: #ddd;
  margin: 10px;
  padding: 10px 10px 20px 10px;
  max-width: 80%;
  float: right;
  border-radius: 5px;
  display: flex;
  position: relative;
  min-width: 160px;
  box-shadow: 4px 4px 7px #ccc;
}
.sent-time {
  position: absolute;
  font-size: 10px;
  bottom: 4px;
  color: #555;
}
.oc-message-sender-name {
  position: absolute;
  bottom: 4px;
  right: 5px;
  font-size: 12px;
  color: #555;
}
.channel {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.chat-list {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
/* 
.message-input {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
}
.message-input .chat-input {
    width: 100%;
    height: 40px;
    display: flex !important;
    content: "";
    border: 1px solid #aaa;
    background: #fff;
    border-radius: 5px;
    resize: none;
    font-size: 20px;
    padding-left: 6px;
}
.message-input .chat-input::placeholder{
    font-size: 20px;
    padding-left: 6px;
}
button.send-message-button.send {
    background: none;
    overlay: none;
    border: 0;
    height: 40px;
    width: 40px;
    position: absolute;
    right: 5px;
    top: 1px;
}
label.file-upload-label {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 3px;
    right: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
}
label.file-upload-label img {
    max-width: 60%;
    height: auto;
} */
