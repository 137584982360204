.react-tabs__tab--selected {
  background: none;
  border-color: #000;
  border-bottom: 2px solid;
}
.tab-title {
  bottom: -2px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
}

.model-content button .text-sm {
  font-size: 30px !important;
}
