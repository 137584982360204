.card-container1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.Heading_normal {
  margin: 10px;
  margin-left: 40px;
  height: 60px;
  font-size: 23px;
}

.card2 {
  width: 245px;
  border-radius: 8px;
  overflow: hidden;
  margin: 20px;
}
.slider-container .slick-dots li {
  height: 120px !important;
  width: 120px;
}
.slider-container .slick-dots {
  position: static;
}
.slider-container .slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 34px !important;
  line-height: 1;
  display: none;
  color: #000 !important;
  position: relative;
}

.radio-btn {
  width: 150px;
  height: 60px;
  padding: 10px, 30px, 10px, 30px;
  border-radius: 53px;
  margin-right: 10px;
}
.select_size label {
  position: absolute;
  left: 60px;
  transform: translate(-50%);
  color: #858585;
  font-size: 24px;
}
.select_size [type="radio"]:checked {
  background-image: none !important;
  background-color: #fff;
}
.select_size [type="radio"]:checked label {
  color: #1c64f2 !important;
}
.product_accordion .dark\:focus\:ring-gray-800:focus {
  --tw-ring-opacity: 0;
}
.product_accordion .dark\:focus\:bg-gray-800:focus {
  --tw-ring-opacity: 0;
}
.product_accordion .dark\:hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background: #f1f5f9;
  color: #000;
}
.product_accordion .dark\:bg-gray-800 {
  --tw-bg-opacity: 0;
  background: #f1f5f9;
  color: #000;
}

.product_accordion .dark\:bg-gray-900 {
  --tw-bg-opacity: 0;

  color: #000 !important;
}
.product_accordion .dark\:text-gray-400 {
  --tw-text-opacity: 1;
  color: #000;
}
.product_accordion {
  border: 0;
}
.size_modal {
  height: 850px !important;
  max-width: 700px !important;
  margin: auto;
  text-align: center;
}
.size_modal svg {
  height: 2rem;
  width: 2rem;
}
.size_modal table {
  background-color: #949292;
  padding: 20px;
  color: #f1f5f9;
  border-radius: 20px;
}
.size_modal tr th {
  font-size: 20px;
  padding: 20px 0;
  background-color: #0ea5e9;
}
.size_modal tr td {
  font-size: 20px;
  padding: 10px 0;
}
.model-content button .text-sm {
  font-size: 30px !important;
}
.profile .modal {
  max-width: 700px;
}
.billboard .slick-dots ul {
  flex-direction: row !important;
}

.date-pick label {
  font-size: 12px !important;
  top: -16px !important;
}
.table-container {
  overflow-x: auto;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th {
  background-color: #f2f2f2;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.responsive-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
.size_modal tr th {
  font-size: 18px;
  padding: 20px 12px;
  background-color: #0ea5e9;
}
@media screen and (max-width: 600px) {
  .responsive-table th,
  .responsive-table td {
    padding: 4px;
    font-size: 10px;
  }

  .size_modal tr td {
    font-size: 16px;
    padding: 10px 0;
  }
}
.accInfo:hover a,
.accInfo:hover svg,
.accInfo:active a,
.accInfo:active svg,
.accInfo:focus a,
.accInfo:focus svg {
  color: #0ea5e9;
}
.slick-next.disabled {
  display: none !important;
}
.phone-number-label .biQaKr input:focus ~ label,
.biQaKr input.active ~ label,
.biQaKr .inputActive ~ label {
  left: 0 !important;
}
#dateOf:focus {
  box-shadow: none !important;
  border: 0 !important;
}
.iyPpbR {
  border: 0;
  border-bottom: 1px solid #9ca3af !important;
}
.react-datepicker-wrapper {
  display: block !important;
}
.react-datepicker-wrapper input {
  width: 100%;
}
.slick-dots li button:before {
  opacity: 0.25;
}
.slick-dots li button:focus:before {
  opacity: 0.25 !important;
}
.slick-dots li.slick-active button:focus:before {
  opacity: 0.75 !important;
}

header {
  z-index: 99;
  background-color: #fff;
  display: flex;
  justify-content: center;
  top: 0;
  position: fixed;
  width: 100%;
}

header .visible {
  top: 0;
  transition: top 0.4s ease-out;
}

header .hidden {
  top: -80px;
  transition: top 0.4s ease-out;
}
.pagination button.active,
.pagination button.hover {
  background-color: #38bdf8;
  color: #fff;
  padding: 0 10px;
}
.pagination-wrapper {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}

.pagination-container {
  display: inline-block;
}

.pagination {
  display: contents;
}

.pagination button {
  margin: 0 5px;
  padding: 0 10px;
}

.pagination button:hover {
  background-color: #38bdf8;
  color: #fff;
}
.react-tabs__tab--selected {
  border: 0px 0px 2px !important;
}
.AccActivity {
  width: 1100px;
}
.react-tel-input .selected-flag {
  cursor: default;
}

.social .cgkAkO {
  width: 30% !important;
}
